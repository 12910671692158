.settingCard {
  justify-content: center;
  align-items: center;
  padding-top: 15%;
  padding-bottom: 5%;
  margin-bottom: 5%;
  cursor: pointer;

  img {
    padding-bottom: 10%;
  }
  b {
    padding-bottom: 5%;
  }
}
